(function() {
  const nav = document.querySelector(".fixed-header");

  function checkPosition() {
    let windowY = window.scrollY;
    if (windowY > 200) {
      // Scrolling UP
      nav.classList.add("is-visible");
      nav.classList.remove("is-hidden");
    } else {
      // Scrolling DOWN
      nav.classList.add("is-hidden");
      nav.classList.remove("is-visible");
    }
  }

  function debounce(func, wait = 10, immediate = true) {
    let timeout;
    return function() {
      let context = this,
        args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  function isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top + 150;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  const fadeElements = document.querySelectorAll(".fade-in");
  function checkIfInView() {
    fadeElements.forEach(el => {
      if (el.classList.contains("shown")) return;
      return isScrolledIntoView(el) ? el.classList.add("shown") : null;
    });
  }

  checkIfInView();

  let navOpen = true;

  const navToggle = document.querySelectorAll(".navigation-toggle");
  const navigation = document.querySelector(".navigation");

  navToggle.forEach(toggle => {
    toggle.addEventListener("click", () => {
      navigation.classList.toggle("navigation--open");
    });
  });

  document.addEventListener("click", function(event) {
    if (event.target.closest([".navigation", ".navigation-toggle"])) return;
    // if (event.target.closest(".navigation")) return;
    // if (event.target.closest(".navigation-toggle")) return;

    navigation.classList.remove("navigation--open");
    navOpen = false;
  });

  window.addEventListener("scroll", debounce(checkPosition));
  window.addEventListener("scroll", checkIfInView);

  const processForm = form => {
    const data = new FormData(form);
    data.append("form-name", "newsletter");
    fetch("/", {
      method: "POST",
      body: data
    })
      .then(() => {
        form.innerHTML = `<div class="form--success">Almost there! Check your inbox for a confirmation e-mail.</div>`;
      })
      .catch(error => {
        form.innerHTML = `<div class="form--error">Error: ${error}</div>`;
      });
  };

  const emailForm = document.querySelector(".email-form");
  if (emailForm) {
    emailForm.addEventListener("submit", e => {
      e.preventDefault();
      processForm(emailForm);
    });
  }
})();
